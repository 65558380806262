<div class="row justify-content-end" style="text-align: end; margin: -1rem">
  <button
    class="button-close"
    mat-icon-button
    aria-label="Example icon-button with a heart icon"
    [mat-dialog-close]
  >
    <mat-icon class="icon-close">close</mat-icon>
  </button>
</div>

<div class="cuerpo text-center">
  <h4>
    {{ data.titulo }} <br />
    <span *ngIf="data.nombre">"{{ data.nombre }}"</span>
  </h4>
  <br />
  <div class="row mb-5">
    <form
      *ngIf="!data.isFormDisabled"
      [formGroup]="EliminarArchivo"
      class="container"
    >
      <div class="form-group">
        <label class="form-label micav-text"
          >{{ data.label ? data.label : "¿Motivo para eliminar? " }}<a>*</a>
        </label>
        <textarea
          rows="4"
          class="form-control form-control-sm micav-input"
          formControlName="motivoEliminar"
        ></textarea>
      </div>
    </form>
  </div>
  <div
    mat-dialog-actions
    class="row justify-content-center"
    style="text-align: end; margin: -1rem"
  >
    <div class="col">
      <button
        class="botonesCard btn-gen-danger w-100"
        mat-button
        [mat-dialog-close]="false"
      >
        Cancelar
      </button>
    </div>
    <div class="col">
      <button
        class="botonesCard w-100 btn-gen-cancel"
        mat-button
        cdkFocusInitial
        (click)="ConfirmarEliminacion()"
      >
        {{ data.confirm }}
      </button>
    </div>
  </div>
</div>
