// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apim: {
    key: "Ocp-Apim-Subscription-Key",
    value: "8315e55d513949f9a7d0b1aaa16e50b5",
  },
  editorApiKey: "7rgrnxyxidfjewk7vdr0zqpxmfvbwafxyjiqgw97wvwkief3",
  mailApi: "/assets/list.json",
  apiBaseUrl: "https://apimanagermicavprod.azure-api.net/api",
  pathSite: "/",
  version: "v1.0.5",
};
export const ROLES = {
  ADMINISTRADORMICAV: "ADMINISTRADORMICAV",
  COLABORADORMICAV: "COLABORADORMICAV",
  COORDINADORMICAV: "COORDINADORMICAV",
  CIUDADANOMICAV: "CIUDADANOMICAV",
  CIUDADANO_ADMIN: "CIUDADANO_ADMIN",
};
